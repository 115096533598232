
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { reactive } from "vue";
  import { ref } from "vue";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { getPricingPlansRequest } from "@/latipay/apis/services/PricingPlansService";


  export default defineComponent({
    name: "pricingPlan",
    components: {},
    setup() {
      const store = useStore();

      const walletId = ref<string | null>(null);
      const merchantId = ref<string | null>(null);
      const gatewayName = ref<string | null>(null);
      const channelName = ref<string | null>(null);


      const pageNumber = ref(1);
      const pageSize = ref(20);
      const sort = ref<string | null>(null);

      const loading = ref(false);


      const formInline = reactive({
        walletId: "",
        merchantId: "",
        gatewayName: "",
        channelName: "",
      });

      const {
        pricingPlansRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getPricingPlans
      } = getPricingPlansRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getPricingPlans(
          formInline.walletId,
          formInline.merchantId,
          formInline.gatewayName,
          formInline.channelName,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(pricingPlansRef);
      };

      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      return {
        getIllustrationsPath,
        formInline,
        walletId,
        merchantId,
        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        pricingPlansRef,
        totalRef,
        pageNumberRef,
        pageSizeRef
      };
    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();
        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }

  });
